@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
*{
  box-sizing: border-box;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
}
.list{
  border: 1px solid #ccc;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}
.list th, .list td{
  padding: 5px;
  border: 1px dashed #ccc;
  background-color: white;
}
.list2{
  border-collapse: collapse;
  border: none;
  width: 100%;
}
.list2 th, .list2 td{
  padding: 10px;
  border: none;
  border-collapse: collapse;
  background-color: white;
}
.bg-inf{
  background-color:#035959 !important;
}
.text-inf{
  color: #035959 !important;
  font-size: 12px !important;
  text-decoration: none;
}
/* .date{
  width: 20%;
} */
.table-name{
  width: 50%;
}
.text-inf:hover{
  background-color: #035959 !important;
  color: white !important;
}
/* .margin{
  margin-top: 200px !important;
} */
.fix{
  margin-bottom: 0;
}

@media (max-width:600px){
  .fix{
    margin-bottom: 130px;
  }
}